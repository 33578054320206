<template>
  <section class="invoice-preview-wrapper">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="invoiceData === undefined"
    >
      <h4 class="alert-heading">
        Erreur de récupération des donnéees de la facture
      </h4>
      <div class="alert-body">
        Aucune facture trouvée avec cet ID. Vérifez
        <b-link
          class="alert-link"
          :to="{ name: 'apps-invoice-list'}"
        >
          La liste de factures
        </b-link>
        pour d'autres factuers.
      </div>
    </b-alert>

    <b-row
      v-if="invoiceData"
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

              <!-- Header: Left Content -->
              <invoice-details />

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h6 class="invoice-title">
                  <span class="invoice-number">{{ invoiceData.facture_no }}</span>
                </h6>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Date:
                  </p>
                  <p class="invoice-date">
                    {{ formatDate(invoiceData.issued_date) }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Date Limite:
                  </p>
                  <p class="invoice-date">
                    {{ formatDate(invoiceData.due_date) }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Invoice Client & Payment Details -->
          <b-card-body
            v-if="invoiceData.client"
            class="invoice-padding pt-0"
          >
            <b-row class="invoice-spacing">

              <!-- Col: Invoice To -->
              <b-col
                cols="12"
                xl="6"
                class="p-0"
              >
                <h6 class="mb-1">
                  Facture à:
                </h6>
                <h6 class="mb-25">
                  {{ invoiceData.client.name }}
                </h6>
              </b-col>

              <!-- Col: Payment Details -->
              <b-col
                xl="6"
                cols="12"
                class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
              >
                <div>
                  <h6 class="mb-2">
                    Details de Payment:
                  </h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          Total Dû:
                        </td>
                        <td><span class="font-weight-bold">{{ amountToCurrency(
                                invoiceData.net_payable
                              ) }}</span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Moyen de Paiement:
                        </td>
                        <td>{{ invoiceData.payment_method.name }}</td>
                      </tr>
                      <!-- <tr>
                        <td class="pr-1">
                          Pays:
                        </td>
                        <td>{{ invoiceData.payment_method.account_country }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          IBAN:
                        </td>
                        <td>{{ invoiceData.payment_method.account_number }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          SWIFT code:
                        </td>
                        <td>{{ invoiceData.payment_method.swift_code }}</td>
                      </tr> -->
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-table-lite
            responsive
            :items="invoiceData.items"
            :fields="tableColumns"
          >
            <template #cell(serviceDescription)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.designation }}
              </b-card-text>
              <b-card-text class="text-nowrap">
                {{ data.item.description }}
              </b-card-text>
            </template>
            <template #cell(total)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ amountToCurrency((data.item.pretax * data.item.qty)) }}
              </b-card-text>
            </template>
          </b-table-lite>

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>

              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <b-card-text class="mb-0">
                  <span class="font-weight-bold">Émetteur de la Facture:</span>
                  <span class="ml-75">{{ invoiceData.sales_person}}</span>
                </b-card-text>
              </b-col>

              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      HT:
                    </p>
                    <p class="invoice-total-amount">
                      {{ amountToCurrency(preTaxes) }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Taxe:
                    </p>
                    <p class="invoice-total-amount">
                      ------
                    </p>
                  </div>
                  <hr class="my-50">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      TTC:
                    </p>
                    <p class="invoice-total-amount">
                      {{ amountToCurrency(total) }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">Note: </span>
            <span>{{ invoiceData.note }}</span>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>

          <!-- Button: Send Invoice -->
          <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-toggle.sidebar-send-invoice
            variant="primary"
            class="mb-75"
            block
          >
            Send Invoice
          </b-button> -->

          <!-- Button: Download -->
          <!-- <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
            class="mb-75"
            block
          >
            Télécharger
          </b-button> -->

          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="success"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Imprimer
          </b-button>

          <!-- Button: Edit -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="warning"
            class="mb-75"
            block
            :to="{ name: 'apps-invoice-edit', params: { id: $route.params.id } }"
          >
            Modifier
          </b-button>

          <!-- Button: Add Payment -->
          <b-button
          @click="
              $router.push({
                name: 'apps-invoice-list',
              })
            "
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-danger"
            class="mb-75"
            block
          >
            Retour
          </b-button>
        </b-card>
      </b-col>
    </b-row>

    <invoice-sidebar-send-invoice />
    <invoice-sidebar-add-payment />
  </section>
</template>

<script>
/* eslint-disable */
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle,
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import invoiceStoreModule from '../invoiceStoreModule'
import InvoiceSidebarSendInvoice from '../InvoiceSidebarSendInvoice.vue'
import InvoiceSidebarAddPayment from '../InvoiceSidebarAddPayment.vue'
import InvoiceDetails from '../InvoiceDetails.vue'

import { amountToCurrency } from "@/utils/formatAmount";
import { formatDate } from "@core/utils/filter";

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,

    Logo,
    InvoiceSidebarAddPayment,
    InvoiceSidebarSendInvoice,
    InvoiceDetails,
  },
  setup() {
    const invoiceData = ref(null)
    const preTaxes = ref(0);
    const total = ref(0);

    // Table Handlers
    const tableColumns = [
      { key: "serviceDescription", label: "Service" },
      { key: "pretax", label: "Taux Coutant", formatter: (val) => (amountToCurrency(val, 'fr-FR', null) + " FCFA") },
      { key: "qty", label: "Qté" },
      { key: "total" },
    ];

    const updateTrackData = () => {
      const services = invoiceData.value.items;

      // 1-/ Add pretax to total pretaxes if user enter pretax (HT) on single service
      preTaxes.value = services.reduce((acc, curr) => {
        return acc + parseFloat(curr.pretax * curr.qty);
      }, 0);

      total.value = services.reduce((acc, curr) => {
        return acc + ((curr.pretax * curr.qty) * (curr.tax_value != null ? curr.tax_value : 1));
      }, 0);

      // Add tax on total taxes if user choose tax on a single service
      // if (attr == "tax") {
      //   let selectedTaxes = []; // use this array to get unique taxe slug

      //   taxes.value = services.reduce((acc, curr) => {
      //     if (!selectedTaxes.includes(curr.tax)) {
      //       let currTax = findTax(curr.tax);

      //       if (currTax != undefined) {
      //         selectedTaxes.push(currTax.slug);
      //         return acc + parseFloat(currTax?.value);
      //       }
      //       return acc;
      //     }
      //     return acc;
      //   }, 0);
      // }
    };

    const getInvoiceData = () => {
      store.dispatch('invoices/get', { id: router.currentRoute.params.id })
      .then(invoice => {
        invoiceData.value = invoice
        updateTrackData()
      })
      .catch(error => {
        if (error.response.status === 404) {
          invoiceData.value = undefined
        }
      })
    }
    getInvoiceData()

    const printInvoice = () => {
      window.print()
    }

    return {
      tableColumns,
      invoiceData,
      preTaxes,
      total,

      printInvoice,
      amountToCurrency,
      formatDate,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
